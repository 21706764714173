import request from '@/utils/request'

// 获取图片列表列表
export function imgList(data) {
    return request({
        url: '/work/video/imgList',
        method: 'post',
        data: data,
        timeout: 100 * 1000,
    })
}

// 视频去水印
export function parseWatermarkBySys(data) {
    return request({
        url: '/work/video/parseWatermarkBySys',
        method: 'post',
        data: data,
        timeout: 100 * 1000,
    })
}