<template>
    <div class="div">
<!--    <el-input v-model="input" placeholder="请输入公众号链接"></el-input>-->
    <el-input v-model="douyin_input" placeholder="请输入抖音链接"></el-input>
      <el-button type="primary" @click="parseWatermarkBySys()">开始解析</el-button>
<!--    <el-button type="primary" @click="imgList()">开始采集</el-button>-->


        <el-input type="textarea" :rows="5" v-model="douyin_link.url"></el-input>
        <el-input type="textarea" :rows="5" v-model="douyin_link.title"></el-input>
      <el-alert
          title="使用说明："
          type="error">
      </el-alert>
      <el-alert
          title="①点击抖音右下角的分享选择复制链接"
          type="error">
      </el-alert>
      <el-alert
          title="②在抖音链接中输入抖音链接"
          type="error">
      </el-alert>
      <el-alert
          title="③等待2s后，第一个为视频下载链接，第二个为视频标题"
          type="error">
      </el-alert>
      <el-alert
          title="④复制视频下载链接，在浏览器打开一个新标签打开视频->鼠标右键->保存视频"
          type="error">
      </el-alert>
      <el-row :gutter="20" >
        <el-col :span="6" v-for="item in list"><div class="grid-content bg-purple"></div>
          <div class="block">
            <span class="demonstration">第一张</span>
            <el-button type="danger" icon="el-icon-delete" circle></el-button>
            <el-image :src="item"></el-image>
          </div>
        </el-col>
      </el-row>

<!--      <el-button type="success">生成视频</el-button>-->
    </div>
</template>

<script>
import { imgList,parseWatermarkBySys} from "@/api/work/video";

export default {
  name: "video",
  components: {
  },
  data() {
    return {
      input: '',
      douyin_input: '',
      douyin_link:'',
      src: 'https://mmbiz.qpic.cn/mmbiz_png/Ia6gU9JNtkpRxgeqZSlRvkf0LjMk5jlO6Feu358s7qEGiaqmnGF0UdUicicodTnR1UsiaU5B4xKXseoSOvNXRTDVTQ/640?wx_fmt=png&wxfrom=5&wx_lazy=1&wx_co=1',
      list:[]
    };
  },
  created() {

  },
  methods: {
//获取公众号数据
    imgList(){
      console.log(this.input)
      imgList(this.input).then(response => {
        this.list=response.datas
      });

    },
    ///抖音去水印
    parseWatermarkBySys(){

      parseWatermarkBySys({"link":this.douyin_input}).then(response => {
        this.douyin_link=response.datas
      });
    }
  }
};
</script>


